<template>
  <BaseCard
    title="datos del contacto"
    :resource="$data.$constants.RESOURCES.RESOURCE_CONTACTS"
    :footer-visible="false"
    actions-visible
    @delete-confirmation="handleDeleteConfirmation"
    @edit-icon-click="$router.push({ name: 'editContact', params: { id: $route.params.id } })"
  >
    <ProfilePanel :profile="contactProfile" />
  </BaseCard>
</template>

<script>
import { mapActions } from 'vuex'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'
import ApiRestService from '@/api/base-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: {
    BaseCard,
    ProfilePanel,
  },
  data() {
    return {
      contact: null,
    }
  },
  computed: {
    contactProfile() {
      if (!this.contact) {
        return []
      }

      return [
        { label: 'Cargo', value: this.contact.position || 'Sin cargo' },
        { label: 'Email', value: this.contact.name || 'Sin email' },
        { label: 'Teléfono', value: this.contact.phone || 'Sin teléfono' },
        { label: 'Descripción / Observaciones', value: this.contact.observations || 'Sin información', type: 'observations' },
      ]
    },
  },
  mounted() {
    this.loadContact()
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async loadContact() {
      try {
        this.setLoading(true)
        const response = await ApiRestService.get(this.$route.params.id, this.$data.$constants.RESOURCES.RESOURCE_CONTACTS)
        this.contact = response.data
      } finally {
        this.setLoading(false)
      }
    },
    async handleDeleteConfirmation() {
      try {
        this.setLoading(true)
        await ApiRestService.delete(this.$route.params.id, this.$data.$constants.RESOURCES.RESOURCE_CONTACTS)
        this.$router.go(-1)
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>
</style>
